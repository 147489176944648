import React, { useEffect, useMemo, useRef } from 'react'
import classNames from 'classnames'
import { ScriptContent } from '@dustin-web/content-components/src/macro-content/node-parsers/script-parser-effects'
import { useLayout } from '@dustin-web/microsite-components/src/hooks'

type ScriptContent = {
  script?: string
  scriptMobile?: string
}
type ImageTagContent = {
  dynamicImage?: string
  dynamicImageMobile?: string
}

type BannerContentBlock = {
  properties: ScriptContent & ImageTagContent
  type: string
}

type Props = ScriptContent & {
  bordered?: boolean
  content: Array<BannerContentBlock>
}

function parseScriptSrc(script: string): string {
  if (script.startsWith('<script')) {
    return script.match(/src="([^"]*)"/i)?.[1] || ''
  } else {
    return script
  }
}

export const BannerflowBannerBlock = ({
  script: upLevelScript,
  scriptMobile: upLevelScriptMobile,
  content,
  bordered,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { responsive } = useLayout()

  const {
    dynamicImage: dynamicImageDesktop,
    scriptMobile: mobileScript,
    dynamicImageMobile,
    script,
  } = content?.[0]?.properties || {}
  const scriptDesktop = upLevelScript || script
  const scriptMobile = upLevelScriptMobile || mobileScript
  const isMobile = responsive.breakpoints.max.SM
  const reloadKey = `${responsive.breakpoints.min.SM}`

  useEffect(() => {
    if (scriptDesktop && scriptMobile) {
      const triggerClick = (target: HTMLDivElement) => {
        const currentIframe = target.querySelector('iframe')
        const dataBannerTarget = currentIframe?.contentDocument?.body.querySelector(
          '[data-banner]'
        ) as HTMLDivElement

        dataBannerTarget?.click()
      }

      const pointerEvent = ({ pointerType, button, target }: PointerEvent) => {
        if (!target || (pointerType === 'mouse' && button !== 0)) {
          return
        }

        triggerClick(target as HTMLDivElement)
      }

      const keyPressEvent = ({ key, target }: KeyboardEvent) => {
        if (!target || key !== 'Enter') {
          return
        }

        triggerClick(target as HTMLDivElement)
      }

      const container = containerRef.current

      container?.addEventListener('pointerup', pointerEvent)
      container?.addEventListener('keypress', keyPressEvent)

      return () => {
        container?.removeEventListener('pointerup', pointerEvent)
        container?.removeEventListener('keypress', keyPressEvent)
      }
    }
  }, [scriptDesktop, scriptMobile])

  const getBanner = (
    script: string | undefined,
    dynamicImage: string | undefined,
    reloadKey: string
  ) => {
    return dynamicImage ? (
      <div dangerouslySetInnerHTML={{ __html: dynamicImage }} />
    ) : (
      script && <ScriptContent async src={parseScriptSrc(script)} key={reloadKey} />
    )
  }

  const bannerScript = isMobile ? scriptMobile : scriptDesktop
  const bannerDynamicImage = isMobile ? dynamicImageMobile : dynamicImageDesktop

  const banner = useMemo(
    () => getBanner(bannerScript, bannerDynamicImage, reloadKey),
    [bannerDynamicImage, bannerScript, reloadKey]
  )

  if (!banner) {
    return
  }

  return (
    <div
      className={classNames('c-bannerflowbannerblock c-bannerflowtest', {
        'c-bannerflowbannerblock--bordered': bordered,
      })}>
      <div
        className={classNames('c-bannerflowbannerblock--banner-wrapper', {
          'c-bannerflowbannerblock--mobile': isMobile,
          'c-bannerflowbannerblock--desktop': !isMobile,
        })}>
        <div className="c-startpage-banner" ref={containerRef}>
          {banner}
        </div>
      </div>
    </div>
  )
}
